import React from 'react';
import '../styles/footer.css'


const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; Arijit Chakma 2024</p>
    </footer>
  );
};

export default Footer;
